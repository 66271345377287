<template>
  <div class="layout-container">
    <router-view />
    <!-- 底部导航 -->
    <van-tabbar v-model="active" route active-color="#0097B2">
      <van-tabbar-item icon="home-o" to="/">{{$t('HOME')}}</van-tabbar-item>
      <van-tabbar-item icon="balance-o" to="/wallet/loansComponent">{{$t('WALLET')}}</van-tabbar-item>
      <van-tabbar-item icon="contact" to="/service">{{$t('SERVICE')}}</van-tabbar-item>
      <van-tabbar-item :dot="isdot" icon="user-o" to="/my">{{$t('MY')}}</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { getunreadInformation } from '@/api/my'
// import { getItem } from '@/utils/storage'
export default {
  name: 'LayoutIndex',
  data () {
    return {
      active: 0,
      isdot: false
    }
  },
  methods: {
    async unread () {
      try {
        const { data } = await getunreadInformation()
        if (data.code === 200) {
          if (data.data.loan || data.data.withdrawal) {
            this.isdot = true
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
  },
  created () {
    // if (getItem('token')) {
    //   console.log(getItem('token'))
    this.unread()
    // setInterval(() => {
    //   this.unread()
    // }, 1 * 1000)
    // }
  }
}
</script>

<style scoped>

</style>
